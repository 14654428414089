<template>
  <section id="landingpage">
    <div class="landingpage-content">
      <v-img
        cover
        :src="require('@/assets/img/usgs.jpeg')"
        gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,.9)"
        height="70vh"
      >
        <template slot="placeholder">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>
          </v-row>
        </template>

        <div class="landingpage-text">
          <h1 class="l-heading">QGIS Anwendergruppe Österreich</h1>
          <p class="lead">
            Verein zur Förderung Freier Software für Geoinformationssysteme
          </p>
        </div>

      </v-img>


    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingPage',
}
</script>

<style scoped>
#landingpage {
  text-align: center;
  min-height: 70vh;
}

.theme--light.v-image {
  color: #fff;
  align-items: center;
}

.landingpage-text {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding: 2rem;
}


.l-heading {
  font-size: 5rem;
  line-height: 1.4;
  font-weight: 400;
  padding-bottom: 2rem;
}

.lead {
  font-size: 1.7rem;
  margin-bottom: 2rem;
}

/* .lead:hover {   -- optional
  text-shadow: 0 0 3px #929292, 0 0 10px #2E7D32;
} */

.landing-span {
  z-index: 10;
  color: #fff;
}
</style>