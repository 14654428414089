<template>
  <v-app id="app">
    <AppHeader />
    <!-- <FloatingBtn /> -->
    <router-view />
    <AppFooter />
    <footer>
      <cookie-law
        theme="base"
        buttonText="Zustimmen"
        buttonLink="/privacy"
        buttonLinkText="Datenschutzrichtlinien"
        buttonLinkNewTab:TRUE
        message="Diese Website verwendet Cookies, um dir das bestmögliche Erlebnis zu gewährleisten. Magst du weiterhin auf unserer Seite surfen, stimmst du unserer Cookie-Nutzung und unserer Datenschutzrichtlinie zu."
        v-on:accept="enableAnalyticsPlugin()"
      ></cookie-law>
    </footer>
  </v-app>
</template>

<script>
import AppHeader from '@/main/Header';
import AppFooter from '@/main/Footer';
// import FloatingBtn from '@/main/FloatingBtn';
import CookieLaw from 'vue-cookie-law';
import { bootstrap } from 'vue-gtag';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    CookieLaw,
    // FloatingBtn
  },
  methods: {
    enableAnalyticsPlugin() {
      bootstrap().then(() => {});
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'QGIS Österreich',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'QGIS Anwendergruppe &#214;sterreich',
      },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'QGIS Österreich' },
      { property: 'og:site_name', content: 'QGIS Österreich' },
      { property: 'og:locale', content: 'de_DE' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.qgis.at/' },

      // Itemprop
      { itemprop: 'addressLocality', content: 'Unterlinden' },
      { itemprop: 'addressRegion', content: 'Wolfurt' },
      { itemprop: 'addressCountry', content: 'Vorarlberg' },
      { itemprop: 'postalCode', content: '6922' },
      { itemprop: 'name', content: 'GISolutions' },
      { itemprop: 'telephone', content: '+43 660 4852504' },
      { itemprop: 'email', content: 'info@qgis.at' },
    ],
    link: [{ rel: 'canonical', href: 'https://www.qgis.at/index.html' }],
  }
}
</script>

<style>
/*
  QGIS Colors:
  light - #51a351
  dark - #387038
  button - #5bb75b
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, sans-serif !important;
  line-height: 1.4;
}

.text-center {
  text-align: center;
  font-size: 1.75rem;
}

.h2-title {
  color: #333;
  padding: 2rem 0;
  font-weight: 500;
  font-size: 2rem;
}

.h3-title {
  color: #387038;
  padding: 1.25rem 0;
  font-weight: 500;
  font-size: 1.4rem;
}

.h4-title {
  color: #333;
  /* padding: 1.2rem 0; */
  font-weight: 500;
  font-size: 1.2rem;
}

.small_text {
  color: #333;
  line-height: 2rem;
  /* letter-spacing: 1px; */
  font-size: 1.15rem;
  text-align: justify;
}

/*  Cookie--bottom Cookie--qgis_at */
.Cookie {
  background-color: rgb(211, 211, 211);
  padding: 3.5rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
}

.Cookie__buttons a {
  text-decoration: none;
}


</style>
