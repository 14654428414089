<template>
  <v-app-bar id="app_header" fixed flat dark :color="bg" >
      <v-img
          class="mx-2"
          :src="require('@/assets/logos/qgisat_logo.png')"
          max-height="50"
          max-width="50"
          contain
        ></v-img>
        
      <router-link :to="{ path: 'home', hash: '#landingpage' }">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>QGIS AT</v-list-item-title>
            <v-list-item-subtitle>Anwendergruppe Österreich</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <v-spacer></v-spacer>
      <v-btn text>
        <router-link :to="{name: 'home', hash: '#qgis_verein'}">
          Verein
        </router-link>
      </v-btn>
      <v-btn text>
        <router-link :to="{name: 'home', hash: '#organisation'}">
          Organisation
        </router-link>
      </v-btn>

      <v-btn text>
        <router-link to="/arbeitsgruppen">
          Arbeitsgruppen
        </router-link>
      </v-btn>
      
      <!-- TODO: Coming soon... @Soyol -->
      <!-- <v-btn text>
        <router-link to="/meetups">
          Anwendertreffen
        </router-link>
      </v-btn> -->
    
      <v-btn text>
        <router-link to="/become_member">
          Mitglied werden
        </router-link>
      </v-btn>

      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    </v-app-bar>
</template>
<script>

export default {
  name: 'AppHeader',
  data: () => ({
    bg : ''
  }),
  mounted () {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  methods: {
    changeColor () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 150) {
        this.bg = 'rgba(33, 33, 33, 0.6)';
      } else if (this.$route.name !== 'home') {
        this.bg = 'rgba(33, 33, 33, 0.6)';
      } else {
         this.bg = 'transparent';
      }

    }
  }
}
</script>
<style scoped>
  .v-toolbar__title {
    padding-left: 1rem;
  }

  .v-application a {
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .v-application a:hover {
    border-bottom: #387038 0.25px solid;
  }

  #app_header{
  z-index: 10;
  }


</style>