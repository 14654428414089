<template>
  <v-card height="150px" width="200px">
    <v-hover v-slot:default="{ hover }">
      <v-img
        :srcset="imageSrcSet"
        :sizes="imageSize"
        height="180px"
        width="300px"
        cover
        :alt="altText"
        v-on:load="startTeaser"
        class="gray-filter"
        :src="imageUrl"
      >
        <template slot="placeholder">
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey"
            ></v-progress-circular>
          </v-row>
        </template>

        <v-expand-transition mode="in-out">
          <div
            v-if="presentTester"
            class="transition-fade-in-fade-out d-flex v-card-overlay-reveal grey darken-3 white--text"
            style="height: 100%; font-weight: bolder"
          >
            <!-- <p>Hover me</p> -->
          </div>
        </v-expand-transition>

        <v-expand-transition mode="out-in">
          <div
            v-if="hover"
            class="transition-fade-in-fade-out d-flex v-card-overlay-reveal grey darken-3 white--text"
            style="height: 100%; font-weight: bolder"
          >
            <p>
              <span v-if="linkUrl.length >0"
                ><a :href="linkUrl" target="_blank">{{
                  transitionText
                }}</a></span
              >
              <span v-else>{{ transitionText }}</span>
            </p>
          </div>
        </v-expand-transition>
      </v-img>
    </v-hover>
  </v-card>
</template>

<script>
export default {
  props: {
    imageUrl: { type: String, default: "" },
    transitionText: { type: String, default: "" },
    altText: { type: String, default: "QGIS Austria alternative image text" },
    imageSize: { type: String, default: "" },
    imageSrcSet: { type: String, default: "" },
    linkUrl: { type: String, default: '' },
  },
  data: function () {
    return {
      presentTester: false,
    };
  },
  methods: {
    startTeaser: function () {
      if (this.presentTester === false) {
        setTimeout(this.startTeaser, 1000);
      }
      this.presentTester = true;
      setTimeout(this.stopTeaser, 1000);
    },
    stopTeaser: function () {
      this.presentTester = false;
    },
  },
  
};
</script>

<style scoped>
.v-card-overlay-reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
p {
  font-size: 1.2rem;
  font-weight: 400;
  z-index: 10;
  opacity: 1;
  color: #fff;
  text-align: center;
}

.v-application a {
  color: #fff;
  text-decoration: none;
}


/* Tablet */
@media (min-width: 600px) and (max-width: 1024px) {
  p {
    font-size: 1rem;
  }
}
/* Smartphones */
@media (max-width: 600px) {
  p {
    font-size: 0.95rem;
  }
}
</style>