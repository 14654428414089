<template>
  <section id="scope" class="pt-16">
    <h2 class="h2-title text-center">Arbeitsgruppen</h2>
    <div class="small_text mb-8">
            Im Verein haben sich mehrere Arbeitsgruppen zu unterschiedlichen Fachthemen gebildet. 
            Wer Interesse hat mitzuarbeiten ist herzlich eingeladen sich bei der jeweilig 
            zuständigen Person zu melden.
          </div>
    <div class="scope-content">
      <div class="scope-img">
         
        <div
          :id="content[item].id"
          v-for="item in Object.keys(content)"
          :key="content[item].id"
        >
          <div class="img-icon">
            <TransitionCard
              @mouseover.native="actualSelected = `${content[item].id}`"
              :imageUrl="content[item].imgUrl"
              :transitionText="content[item].name"
            >
            </TransitionCard>
          </div>
        </div>
      </div>
      <!-- Scope descriptions -->
      <div class="scope-text">
        <v-card
          outlined
          shaped
          height="90%"
          width="90%"
        >
          <v-card-title>
            {{ actualTitle }}
          </v-card-title>
          <v-card-subtitle>
            {{ actualDescription }}
          </v-card-subtitle>
          <v-card-text>
            <hr>
            <v-chip-group
              v-model="selection"
              active-class="red darken-4 white--text"
              column >
              <v-chip
                v-for="item in actualContacts"
                :key="item"
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import TransitionCard from '@/components/TransitionCard';

export default {
  name: 'Scopes',
  components: { TransitionCard },
  data: () => ({
    selection: 1,
    actualSelected: 'workshops',
    content: {
      workshops: {
        id: 'workshops',
        name: 'Workshops',
        title: 'GIS Ausbildung und Schulung',
        description: 'Für Behörden und Unternehmen bieten wir bedürfnisorientiert QGIS Schulungen an',
        contactPersons: [ 'Paul Stampfl' ],
        email: '',
        imgUrl: require('@/assets/img/workshops.jpg'),
      },
      hydrology: {
        id: 'hydrology',
        name: 'Hydrologisches Modellieren',
        title: 'Hydrologische Modellierungen und Urbane Schutzzonen',
        description: 'Wildbach und Lawinenverbauung auch hydrologisches Modellieren für städtischen Gebieten',
        contactPersons: [ 'Werner Macho' ],
        email: '',
        imgUrl: require('@/assets/img/hydrology.jpg'),
      },
      webapplication: {
        id: 'webapplication',
        name: 'Webanwendungen',
        title: 'Webclients und Webanwendungen',
        description: 'Open Source Software in GIS Bereich. Einsatz von Frameworks in Geodatenrelevanten Webanwendungen',
        contactPersons: [ 'Franz Gusenbauer', 'Soyol Marksteiner' ],
        email: '',
        imgUrl: require('@/assets/img/webdevelopment.jpg'),
      },
      
      // technology: {
      //   id: 'technology',
      //   name: 'Technologien',
      //   title: 'Technologische Angelegenheiten',
      //   description: 'Datenbanken, Anwendungen und Entwicklungen',
      //   contactPersons: ['Heiko Kinzel', 'Franz Gusenbauer', 'Christian Schilchegger'],
      //   imgUrl: require('@/assets/img/technology.jpg'),
      // },
      // marketing: {
      //   id: 'marketing',
      //   name: 'Marketing',
      //   title: 'Marketing und Internationalisierung',
      //   description: 'Kommunikation und Social Media für QGIS nach Außen',
      //   contactPersons: [ 'Ralf Sembritzki', 'Soyol Marksteiner' ],
      //   imgUrl: require('@/assets/img/marketing.jpg'),
      // },
      // remoteSensing: {
      //   id: 'remoteSensing',
      //   name: 'Fernerkundung',
      //   title: 'Fernerkundung, Drohnenbefliegung im Forst und Naturgefahrenbereich',
      //   description: 'Daten aus Befliegung -> automatisierte Datenfluss zur Informationsverarbeitung',
      //   contactPersons: [ 'Werner Macho' ],
      //   imgUrl: require('@/assets/img/drone.jpg'),
      // },
    }
  }),
  computed: {
    actualTitle() {
      if (this.actualSelected === 'default') {
        return 'Titel';
      }

      return this.content[this.actualSelected].title;
    },
    actualDescription() {
      if (this.actualSelected === 'default') {
        return 'Beschreibung';
      }

      return this.content[this.actualSelected].description;
    },
    actualContacts() {
      if (this.actualSelected === 'default') {
        return ['Kontakt Personen'];
      }

      return this.content[this.actualSelected].contactPersons;
    }

  }
}
</script>

<style scoped>
  #scope {
    min-height: 60vh;
    padding: 0 8vw;
  }

  .v-sheet.v-card.v-sheet--shaped {
    border-radius: 1px 4rem;
    border: rgba(81, 163, 81, 0.8) solid 1px;
  }

  .v-card__title {
    padding: 30% 1rem 1rem 1rem;
    word-break: normal;
  }
  .v-card__subtitle {
    padding-top: 1rem;
  }
  .v-card__text {
    position: absolute;
    bottom: 5rem;
  }
  hr {
    position: relative;
    top: 45%;
    border: none;
  }
  hr::before {
    background-color: rgba(33, 33, 33, 0.3);
    content: '';
    display: block;
    height: 1px;
    left: 5%;
    position: absolute;
    width: 90%;
    z-index: 1;
  }
  .v-slide-group {
    padding: 1rem !important;

  }
  .scope-content {
    height: 700px;
    display: flex;
    flex-direction: row;
  }

  .scope-img {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 40%);
    grid-template-rows: repeat(3, 25%);
    gap: 10%;
    padding: 1.25rem;
    padding-left: 7%;
  }

  .scope-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }


</style>