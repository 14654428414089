<template>
  <section id="qgis_intro">
    <h2 class="h2-title text-center">
      Ein freies Geographisches Informationssystem 
    </h2>
    <div class="small_text">
      QGIS ist ein freies und benutzerfreundliches Geoinformationssystem zum Betrachten,
      Bearbeiten, Erfassen und Analysieren von raumbezogenen Daten und Information, 
      das unter der <strong>GNU General Public License</strong> lizenziert ist. 
    </div>
    <div class="d-flex flex-wrap justify-space-between qgis-content">
      <v-card 
        elevation="4"
        class="mx-auto qgis-card"
        width="350"
        height="300"
        v-for="(item, i) in items"
        :key="i"
        @click="myFunction"
      >
        <v-icon color="#408240" size="50">
          {{item.icon}}
        </v-icon> 
        <v-card-title>{{item.title}}</v-card-title>
        <v-card-subtitle>{{item.subtitle}}</v-card-subtitle>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  name: 'QGIS-Intro',
  data: () => ({
    items: [
      { 
        icon: 'desktop_windows',
        title: 'Betriebssysteme',
        subtitle: 'Es läuft unter Linux, Unix, Mac OSX, Windows und Android und unterstützt eine Vielzahl von Vektor-, Raster- und Datenformate und -funktionen. Z.B PostGIS/PostgreSQL und SpatiaLite'
      },
      {
        icon: 'settings',
        title: 'Datenverarbeitung',
        subtitle: 'Wesentliche Merkmale der Applikation sind die breite Unterstützung Vektor- und Rasterdaten, zum Erfassen von Vektordaten sowie eine Druckzusammenstellung zum Erstellen von qualitativ hochwertigen Kartenausdrucken.'
      },
      {
        icon: 'api',
        title: 'Geodateninfrastruktur - API',
        subtitle: 'QGIS bietet eine Schnittstellenarchitektur, die das Anwenden von QGIS-Funktionalitäten in eigenen Programmen oder das Schreiben von Plugins zur Funktionserweiterung erlaubt.'
      },
      {
        icon: 'web',
        title: 'QGIS-Server',
        subtitle: 'Der QGIS Server ermöglicht die Veröffentlichung von QGIS-Projekten und Layern als OGC kompatible WMS-, WFS- und WCS-Dienste. Über den Server lassen sich Layer, Attribute, Layouts und Koordinatensysteme exportieren und steuern. '
      },
      {
        icon: 'public',
        title: 'QGIS-Webclienten',
        subtitle: 'QGIS Projekte lassen sich einfach im Web veröffentlichen. Die Anwender profitieren von modernen Symbolisierung-, Beschriftung- und Überblendungsfunktionen und umfangreichen kartographischen Designmöglichkeiten.'
      },
      {
        icon: 'devices_other',
        title: 'QGIS auf Handy und Tablet',
        subtitle: 'Die QGIS-Anwendung hört nicht auf dem Desktop auf. Mit verschiedenen Touch-optimierten Apps von Drittanbietern kann die QGIS Geodateninfrastruktur in der Feldarbeit eingesetzt werden.'
      }
    ]
  }),
  methods: {
    myFunction () {
      console.log('maybe open new blank tab...');
    }
  }
}
</script>

<style scoped>
  #qgis_intro {
    background-color: #f4f4f4;
    padding: 1.5rem;
  }

  .small_text {
    padding: 0 8vw;
  }

  .qgis-content {
    display: grid !important;
    grid-template-columns: repeat(3, 33%);
    gap: 1rem;
  }

  .qgis-card {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .v-card__subtitle {
    text-align: left;
    padding: 1rem 1.75rem;
  }

  .v-icon {
    padding-top: 1rem;
  }

</style>