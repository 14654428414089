<template>
  <footer class="footer" id="sticky_footer">
    <div class="footer_content">
      <div id="kontakt" class="footer_item">
        <h2>Kontakt</h2>
        <hr />
        <a href="mailto:info@qgis.at">
          <p>info@qgis.at</p>
        </a>
        <p>www.qgis.at</p>
        <p>phone: +43 660 4852504</p>
        <router-link  :to="{name: 'privacy'}">
          <a href="">Datenschutzerklärung</a>
        </router-link>
      </div>

      <div id="adresse" class="footer_item">
        <h2>Adresse</h2>
        <hr />
        <p>QGIS Anwendergruppe Österreich</p>
        <p>Unterlinden 16a</p>
        <p>6922 Wolfurt</p>
        <p>Voarlberg | Österreich</p>
      </div>

      <div id="social_media" class="footer_item">
        <h2>Social Media</h2>
        <hr />
        <div class="social_content">
          <div class="socialmedia_icon">
            <v-btn
              outlined fab color="#fff"
              href="https://twitter.com/QGIS_AT/"
              target="_blank"
            >
              <v-icon>fab fa-twitter</v-icon>
            </v-btn>
          </div>

          <div class="socialmedia_icon">
            <!-- Youtube Kanal erstellen -->
            <v-btn
              outlined fab color="#fff"
              href="#"
              target="_blank"
            >
              <v-icon>fab fa-youtube</v-icon>
            </v-btn>
          </div>
           <div class="socialmedia_icon">
            <v-btn
              outlined fab color="#fff"
              href="https://github.com/QGIS-Austria"
              target="_blank"
            >
              <v-icon>fab fa-github</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="gisolutions">
      Designed and created by
      <a href="https://gisolutions.at" target="_blank">
        GISolutions e.U
      </a>
      in {{ new Date().getFullYear() }}
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'AppFooter',
  };
</script>

<style scoped>
  .footer_content {
    height: 450px;
    background-color: #333;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
  }

  .footer_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3rem;
    padding-left: 3rem;
  }
  .footer_item a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem !important;
  }
  .footer_item a:hover {
    color: #5bb75b;
  }

  h2 {
    font-weight: 400;
    font-size: 1.75rem;
    letter-spacing: 2px;
  }

  p {
    font-size: 1.1rem;
    color: #fff;
  }

  hr {
    width: 80%;
    color: #fff;
    border-top: 1px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .social_content {
    display: flex;
  }
  .socialmedia_icon {
    padding-right: 2.5rem;
  }
  .gisolutions {
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
  }

  .gisolutions a {
    text-decoration: none;
    color: #fff;
    padding: 0 0.4rem;
  }
  .gisolutions a:hover {
    color: teal;
  }
</style>
