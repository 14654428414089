import Home from '@/pages/Home';

// Pages as own Component
import Privacy from '@/pages/Privacy';
import BecomeMember from '@/pages/BecomeMember';
import Scopes from '@/pages/Scopes';

export const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    props: true
  },
  {
    path: '/privacy',
    component: Privacy,
    name: 'privacy'
  },
  {
    path: '/arbeitsgruppen',
    component: Scopes,
    name: 'arbeitsgruppen'
  },
  {
    path: '/become_member',
    component: BecomeMember,
    name: 'become_member'
  },


  {
    path: '*',
    redirect: '/'
  },
];
